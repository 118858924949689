.SubmitNew-Main-Con {
    /* height: 70vh; */
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Buttons-Row-1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Buttons-Row-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 3%;
}

.formButtons {
    width: 180px;
    height: 180px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.avatar-uploader > .ant-upload {
  width: 700px;
  height: 300px;
}

.banner-uploader > .ant-upload {
  width: 820px;
  height: 312px;
}


/* .avatar-uploader > .ant-upload {
  width: 512px;
  height: 512px;
}


.banner-uploader > .ant-upload {
  width: 820px;
  height: 312px;
} */


.transparent-button {
  border: none;
  vertical-align: middle;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
}

/* Set antd button with type = "primary" color to green */
.ant-btn-primary {
  background-color: #1BAA4A;
}